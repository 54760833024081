import React from 'react';
import Seo from '../components/Seo';
import { contactHolder } from './contact.module.scss';

export default function ContactPage() {
  return (
    <article className={contactHolder}>
      <h1>Contact MCX</h1>
    </article>
  );
}

export const Head = () => <Seo title="Contact MCX Films" />;
